import React, { useEffect, useState } from "react";
import { useUser } from "../../stateManagement/UserContext";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import CryptoJS from "crypto-js";
import Loading from "../../Component/loading/Loading";
import { RiSubtractFill } from "react-icons/ri";
import Img1 from "../../Component/logorci.png";
import toast from "react-hot-toast";

function loadScript(src) {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
}

function getDate() {
  const today = new Date();
  const month = today.getMonth() + 1;
  const year = today.getFullYear();
  const date = today.getDate();
  return `${month}/${date}/${year}`;
}

const Addtocart = () => {
  const { user } = useUser();
  const navigate = useNavigate();

  const [currentDate, setCurrentDate] = useState(getDate());
  const [cart, setCart] = useState([]);
  const [status, setStatus] = useState(true);
  const [loading, setLoading] = useState(false);
  const [quantities, setQuantities] = useState({});
  const [couponCode, setCouponCode] = useState(""); 
  const [discountAmount, setDiscountAmount] = useState(0);
  const [lastdetail, setLastdetail] = useState(0);
  const [cod, setCod] = useState(50);
  const [paycheck , setPaycheck] = useState(0);
  const [checkpayfun, setCheckpayfun] = useState(false);
  
    useEffect(() => {
      if (checkpayfun) {
        order_accept();
      }
    }, [checkpayfun]); 
  

  const [postData, setPostData] = useState({
    order_date: currentDate,
    product_details: {},
    user_details: {},
    user_id: "",
    customer_ip: "",
    phone: "",
    alt_phone: "",
    Address: "",
    location: "",
    pincode: "",
    payment: "",
    payment_id: "",
    track: "order process",
    binary_track: "0",
    amount: "",
    Track_product: "",
    expected_date: "",
    product_qun: [],
  });

  const [address, setAddress] = useState(false);
  const [binpay, setBinpay] = useState(false);
  const [gateway, setGateway] = useState(false);
  const [checkpay, setCheckpay] = useState(false);
  const [successful, setSuccessful] = useState(false);

  useEffect(() => {
    axios
      .get("https://api.ipify.org?format=json")
      .then((response) => {
        setPostData((prevState) => ({
          ...prevState,
          customer_ip: response.data.ip, // Set customer_ip to the fetched IP address
        }));
      })
      .catch((error) => {
        console.error("Error fetching IP address:", error);
      });
  }, []);

  const handleIncrement = (index, maxQuantity) => {
    setQuantities((prevQuantities) => {
      const currentQuantity = prevQuantities[index] || 1;
      if (maxQuantity > currentQuantity) {
        return { ...prevQuantities, [index]: currentQuantity + 1 };
      } else {
        console.log(maxQuantity);
        return prevQuantities;
      }
    });
  };

  const handleDecrement = (index) => {
    setQuantities((prevQuantities) => {
      const currentQuantity = prevQuantities[index] || 1;
      if (currentQuantity > 1) {
        return { ...prevQuantities, [index]: currentQuantity - 1 };
      } else {
        return prevQuantities;
      }
    });
  };

  const totalAmount = cart.reduce((acc, item, index) => {
    const quantity = quantities[index] || 1;
    return acc + item.product_details.discount * quantity;
  }, 0);

  const applyCoupon = () => {
    const couponCodeToApply = "NEWMEMBER10";
    if (couponCode === couponCodeToApply) {
      const couponDiscountPercentage = 0.1;
      const discountedAmount = Math.round(totalAmount * couponDiscountPercentage);
      setDiscountAmount(discountedAmount);
      setPostData((prevState) => ({
        ...prevState,
        amount: totalAmount - discountedAmount,
      }));
    } else {
      setDiscountAmount(0);
      setPostData((prevState) => ({
        ...prevState,
        amount: totalAmount,
      }));
    }
  };

  useEffect(() => {
    applyCoupon();

    // eslint-disable-next-line
  }, [totalAmount]);

  useEffect(() => {
    const productQun = cart.map((item, index) => ({
      product_id: item.product_details.unique_id,
      size: item.size,
      qun: quantities[index] || 1,
    }));

    setPostData((prevState) => ({
      ...prevState,
      product_qun: productQun,
    }));
  }, [cart, quantities]);

  useEffect(() => {
    const newProductDetails = cart.map((item) => item.product_details);

    setPostData((prevState) => ({
      ...prevState,
      user_details: user,
      user_id: user.user_id,
      email: user.user_email,
    }));

    setPostData((prevState) => ({
      ...prevState,
      product_details: newProductDetails,
      quantities: quantities,
    }));
    // eslint-disable-next-line
  }, [cart]);

  const secret = process.env.REACT_APP_SECRET;
  const payload = {
    iss: process.env.REACT_APP_SECRET_ISS,
    aud: process.env.REACT_APP_SECRET_AUD,
  };
  const header = {
    alg: process.env.REACT_APP_SECRET_ALG,
  };
  const encodeHeader = btoa(JSON.stringify(header));
  const payloadString = btoa(JSON.stringify(payload));
  const signature = CryptoJS.HmacSHA256(
    encodeHeader + "." + payloadString,
    secret
  ).toString(CryptoJS.enc.Base64);
  const jwt = `${encodeHeader}.${payloadString}.${signature}`;
  const api = process.env.REACT_APP_API_WISH_D;

  const cart_views = () => {
    if (user) {
      setLoading(true);
      setStatus(true);

      axios
        .get(`https:${api}?user_id=${user.user_id}`, {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        })
        .then((response) => {
          if (response.data.message === "Cart items retrieved successfully.") {
            setCart(response.data.cart_items); // Update cart with the retrieved items
            setStatus(false);
          }
        })
        .catch((error) => {
          console.error("Error retrieving cart:", error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const carttoremove = process.env.REACT_APP_API_ADDTOCART_A;
  const carttoremove2 = process.env.REACT_APP_API_WISH_D;

  const remove_chart = (product_id) => {
    setLoading(true);

    axios
      .delete(
        `https:${carttoremove2}?user_id=${user.user_id}&product_id=${product_id}`,
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        }
      )
      .then((response) => {
        if (
          response.data.message === "Product removed from cart successfully."
        ) {
          cart_views(); // Refresh the cart after removal
        }
      })
      .catch((error) => {
        console.error("Error removing from cart:", error);
      })
      .finally(() => {
        setLoading(false); // Set loading to false after the API call is complete
      });
  };

  useEffect(() => {
    cart_views();
    // eslint-disable-next-line
  }, []);

  const acceptorder = process.env.REACT_APP_API_ADDTOCART_B;

  const order_accept = () => {
    axios
      .post(`https:${acceptorder}`, postData, {
        headers: {
          "Content-Type": "multipart/dataToPost",
          Authorization: `Bearer ${jwt}`,
        },
      })
      .then((response) => {
        console.log(response.data);
        if (response.data.message === "successful order data") {
          axios
            .delete(`https:${carttoremove}?user_id=${postData.user_id}`, {
              headers: {
                "Content-Type": "multipart/dataToPost",
                Authorization: `Bearer ${jwt}`,
              },
            })
            .then((response) => {
              if (
                response.data.message ===
                "Product removed from cart successfully."
              ) {
                toast.success("successfully order");
                toast.success("order confirm");
                navigate("/");
              }
            })
            .catch((error) => {
              console.error("Error posting data:", error);
            });
        }
      })
      .catch((error) => {
        console.error("Error posting data:", error);
      });
    setCheckpay(false);
    setSuccessful(true);
  };

  const outcadd = process.env.REACT_APP_API_ADDTOCART_C;

  async function showRazorpay() {
    const res = await loadScript(`https:${outcadd}`);

    if (!res) {
      alert("Razorpay SDK failed to load. Are you online?");
      return;
    }

    const solid = process.env.REACT_APP_API_SOLID;

    const options = {
      key: `${solid}`,
      currency: "INR", // Change this to your desired currency
      amount: (totalAmount - discountAmount) * 100, // Change this to the desired amount in paise or cents
      name: "purchase",
      description: "your bill account",
      image: Img1,
      handler: function (response) {
        if (response.razorpay_payment_id) {
          setPostData((prevData) => ({
            ...prevData,
            payment_id: response.razorpay_payment_id,
          }));
          setPostData((prevData) => ({
            ...prevData,
            payment: "online mode",
          }));
          setPaycheck(1);
          setCheckpay(true);
          setCheckpayfun(true);
        } else {
          alert("Transaction failed. Please try again later.");
        }
      },
    };
    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  }
  const offline = () => {
    setGateway(false);
    setCheckpay(true);
    setCod(0)
    setPostData((prevData) => ({
      ...prevData,
      payment: "Cash on delivery",
      amount: ((totalAmount - discountAmount )+ 50)
    }));
  };

  const online = () => {
    setGateway(!gateway);
    setCheckpay(false);
    setCod(1);
    setPostData((prevData) => ({
      ...prevData,
      payment: "Cash on delivery",
      amount: (totalAmount - discountAmount )
    }));
  };

  if (loading) {
    return (
      <div>
        {" "}
        <Loading />{" "}
      </div>
    );
  }

  if (status) {
    return <div> Not Found </div>;
  }

  return (
    <>
      <div>
        {!address && (
          <div>
            <>
              <div className="pro_home_container">
                <div className="pro_home">
                  {cart.map((item, index) => (
                    <div key={index}>
                      <>
                        <Link
                          to={
                            item.product_details.catalog
                              ? `/productsingle/${item.product_details.id}?sizes=${item.product_details.catalog}/viewpro.php`
                              : `/product/${item.product_details.id}`
                          }
                        >
                          <div className="pro_part">
                            <div className="product_box_sliding_container_img">
                              <div className="home_product_box_image">
                                <img
                                  src={
                                    item.product_details.catalog
                                      ? `${process.env.REACT_APP_API_IMAGE}${item.product_details.thumnail}`
                                      : `${process.env.REACT_APP_API_IMAGE_SECOND}${item.product_details.thumnail}`
                                  }
                                  alt=""
                                  width="100%"
                                  height="100%"
                                />
                              </div>
                            </div>
                            <div className="pro_name">
                              <p>{item.product_details.name}</p>
                            </div>
                            <div className="pro_price">
                              <p>Rs-{item.product_details.discount}</p>
                            </div>
                          </div>
                        </Link>
                        <div
                          onClick={() =>
                            remove_chart(item.product_details.unique_id)
                          }
                          className="Remove_cart_button"
                        >
                          <p>Remove Cart</p>
                        </div>
                      </>
                    </div>
                  ))}
                </div>
              </div>
            </>
            <>
              <div className="addtocart_head_order">
                <div className="addtocart_head_order_p">
                  <p>Price Summary </p>
                </div>
              </div>
              <div className="product_bill_container product_bill_container2">
                <div className="">
                  {cart.map((item, index) => (
                    <div key={index}>
                      <div className="product_checkout product_checkout2">
                        <div className="img_checkout">
                          <img
                            src={
                              item.product_details.catalog
                                ? `${process.env.REACT_APP_API_IMAGE}${item.product_details.thumnail}`
                                : `${process.env.REACT_APP_API_IMAGE_SECOND}${item.product_details.thumnail}`
                            }
                            alt=""
                            width="100%"
                          />
                        </div>
                        <div className="check_pro_details">
                          <p className="p_part1_checkout">
                            {item.product_details.name}
                          </p>
                          <p className="p_part2_checkout">
                            {item.product_details.description
                              .split(" ")
                              .slice(0, 10)
                              .join(" ")}
                            {item.product_details.description.split(" ")
                              .length > 10 && "..."}
                          </p>
                          <p className="p_part3_checkout">
                            RS: {item.product_details.discount}
                          </p>
                          <p className="p_part3_checkout">
                            Size: {item.size.replace("no", "").toUpperCase()}
                          </p>
                          
                          <div className="quantity_checkout_container">
                            {item.product_details?.[item.size] >
                            (quantities[index] || 1) ? (
                              <div className="quantity_checkout">
                                <p className="p_checkout_quan">Qun</p>
                                <div>
                                  <button
                                    onClick={() => handleDecrement(index)}
                                    className="but_1"
                                  >
                                    <RiSubtractFill />
                                  </button>
                                </div>
                                <div className="input_checkout">
                                  <input
                                    type="number"
                                    value={quantities[index] || 1}
                                    readOnly
                                  />
                                </div>
                                <div>
                                  <button
                                    onClick={() =>
                                      handleIncrement(
                                        index,
                                        item.product_details?.[item.size]
                                      )
                                    }
                                    className="but_2"
                                  >
                                    +
                                  </button>
                                </div>
                              </div>
                            ) : (
                              <div className="quantity_checkout">
                                <p>Quantity</p>
                                <div>
                                  <button
                                    onClick={() => handleDecrement(index)}
                                    className="but_1"
                                  >
                                    <RiSubtractFill />
                                  </button>
                                </div>
                                <div className="input_checkout">
                                  <input
                                    type="number"
                                    value={quantities[index] || 1}
                                    readOnly
                                  />
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>

                <div className="product_summary">
                  <div className="head_price_sum">
                    <p>PRICE SUMMARY</p>
                  </div>
                  <div className="summary_bill">
                    <div className="con_bill">
                      <p>Total (Incl. of taxes)</p>
                    </div>
                    <div className="con_bill">
                      <p>RS:-{totalAmount}</p>
                    </div>
                  </div>
                  <div className="summary_bill">
                    <div className="con_bill">
                      <p>Subtotal</p>
                    </div>
                    <div className="con_bill">
                      <p>RS: {totalAmount}</p>
                    </div>
                  </div>
                  <div className="summary_bill">
                    <div className="con_bill">
                      <p>Coupon Apply</p>
                    </div>
                    <div className="con_bill">
                      <input
                        type="text"
                        value={couponCode}
                        onChange={(e) => setCouponCode(e.target.value)}
                      />
                      <button onClick={applyCoupon}>Apply</button>
                    </div>
                  </div>
                  {discountAmount > 0 && (
                    <div className="summary_bill">
                      <div className="con_bill">
                        <p>Coupon Discount</p>
                      </div>
                      <div className="con_bill">
                        <p>RS:-{discountAmount}</p>
                      </div> 
                    </div>
                  )}
                  <hr className="hr_space" />
                  <div className="con_bill_add">
                    <div className="con_bill_part2">
                      <p>Total</p>
                      <p>RS: {totalAmount - discountAmount}</p>
                    </div>
                    <div
                      className="button_add_address"
                      onClick={() => setAddress(!address)}
                    >
                      <p>ADD ADDRESS</p>
                    </div>
                  </div>
                </div>
              </div>
            </>
          </div>
        )}
        {address && !binpay && (
          <div className="shipping_checkout">
            <div className="ship_head">
              <p>Shipping details:</p>
            </div>
            <div className="ship_input-container">
              <div className="wrap_ship">
                <div className="ship_input">
                  <input
                    type="text"
                    placeholder="pincode"
                    value={postData.pincode}
                    id="pin"
                    pattern="[0-9]{6}"
                    onChange={(e) =>
                      setPostData({ ...postData, pincode: e.target.value })
                    }
                  />
                </div>
                <div className="ship_input">
                  <input
                    type="text"
                    placeholder="Address"
                    value={postData.Address}
                    onChange={(e) =>
                      setPostData({ ...postData, Address: e.target.value })
                    }
                  />
                </div>
                <div className="ship_input">
                  <input
                    type="text"
                    placeholder="City/town"
                    value={postData.location}
                    onChange={(e) =>
                      setPostData({ ...postData, location: e.target.value })
                    }
                  />
                </div>
                <div className="ship_input">
                  <input
                    type="text"
                    placeholder="Mobile number"
                    value={postData.phone}
                    onChange={(e) =>
                      setPostData({ ...postData, phone: e.target.value })
                    }
                  />
                </div>
                <div className="ship_input">
                  <input
                    type="text"
                    placeholder="alternate number"
                    value={postData.alt_phone}
                    onChange={(e) =>
                      setPostData({ ...postData, alt_phone: e.target.value })
                    }
                  />
                </div>
              </div>
            </div>
            <div onClick={() => { setBinpay(!binpay); setLastdetail(1) }} className="ship_next">
              <div className="but_ship_next">Next step</div>
            </div>
          </div>
        )}
        {binpay && !successful && (
          <div className="shipping_checkout">
            <div className="ship_head">
              <p>Payment details:</p>
            </div>
            <div className="payment_content">
              <p>Please select the payment mode, then confirm the order.</p>
            </div>
            <div className="ship_input-container">
              <div className="wrap_ship">
                <div>
                  <div onClick={offline} className="con_pay_check">
                    <p>Cash On delivery</p>
                  </div>
                  <div
                    onClick={online}
                    className="con_pay_check"
                  >
                    <p>Online payment</p>
                  </div>
                  {gateway ? (
                    <div>
                      <header>
                        <button
                          onClick={showRazorpay}
                          className="but_ship_next"
                        >
                          Pay
                        </button>
                      </header>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
        {
          lastdetail > 0 && !successful && (
            <div className="last_summary">
              <div className="product_summary">
                <div className="head_price_sum">
                  <p>PRICE SUMMARY</p>
                </div>
                <div className="summary_bill">
                  <div className="con_bill">
                    <p>Total (Incl. of taxes)</p>
                  </div>
                  <div className="con_bill">
                    <p>RS: {totalAmount }</p>
                  </div>
                </div>
                <div className="summary_bill">
                  <div className="con_bill">
                    <p>Shipping Charges</p>
                  </div>
                  <div className="con_bill">
                    <p>{cod > 0 ? "FREE" : "RS: 50"}</p>
                  </div>
                </div>
                <div className="summary_bill">
                  <div className="con_bill">
                    <p>Subtotal</p>
                  </div>
                  <div className="con_bill">
                    <p>RS: {totalAmount}</p>
                  </div>
                </div>
                {discountAmount > 0 && (
                  <div className="summary_bill">
                    <div className="con_bill">
                      <p>Coupon Discount</p>
                    </div>
                    <div className="con_bill">
                      <p>RS: {discountAmount}</p>
                    </div>
                  </div>
                )}
                <hr className="hr_space" />
                <div className="con_bill_add">
                  <div className="con_bill_part2">
                    <p>Total</p>
                  </div>
                  <div>
                    <p>RS: {postData.amount}</p>
                  </div>
                </div>
              </div>
            </div>
          )
        }
        {
          paycheck > 0 && !successful &&
          <><div className="payment_success"><p>Your payment has been successfully completed. Now, please click the confirm button to confirm your order.</p></div></> 
        }

        {checkpay && !successful && (
          <div className="butt_accept">
            <button onClick={order_accept} className="but_ship_next">
              Order Confirm
            </button>{" "}
          </div>
        )}

        {successful && <div> you order has been accept . </div>}
      </div>
    </>
  );
};

export default Addtocart;
