import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useUser } from "../../stateManagement/UserContext";
import Img1 from "../../Component/logorci.png";
import axios from "axios";
import CryptoJS from "crypto-js";
import "./checkout.css";
import { useLocation } from "react-router-dom";
import { RiSubtractFill } from "react-icons/ri";
import toast from "react-hot-toast";

function loadScript(src) {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
}

function getDate() {
  const today = new Date();
  const month = today.getMonth() + 1;
  const year = today.getFullYear();
  const date = today.getDate();
  return `${month}/${date}/${year}`;
}

const Checkout = () => {
  // eslint-disable-next-line
  const [currentDate, setCurrentDate] = useState(getDate());
  const navigate = useNavigate();
  const [couponCode, setCouponCode] = useState(""); // State variable to track the coupon code
  const [discountAmount, setDiscountAmount] = useState(0);
  const [cod, setCod] = useState(50);
  const [totalamount, setTotalamount] = useState(0);
  useEffect(() => {
    axios
      .get("https://api.ipify.org?format=json")
      .then((response) => {
        setPostData((prevState) => ({
          ...prevState,
          customer_ip: response.data.ip, // Set customer_ip to the fetched IP address
        }));
      })
      .catch((error) => {
        console.error("Error fetching IP address:", error);
      });
  }, []);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const size = queryParams.get("size");
  const apicall = queryParams.get("apicall");
  const { productId } = useParams();
  const [qun, setQuan] = useState(1);
  const { user } = useUser();
  const [store, setstore] = useState();
  const [address, setAddress] = useState(false);
  const [binpay, setBinpay] = useState(false);
  const [gateway, setGateway] = useState(false);
  const [checkpay, setCheckpay] = useState(false);
  const [successful, setSuccessful] = useState(false);
  const [lastdetail, setLastdetail] = useState(0);
  const [paycheck , setPaycheck] = useState(0);
  const [checkpayfun, setCheckpayfun] = useState(false);

  useEffect(() => {
    if (checkpayfun) {
      order_accept();
    }
  }, [checkpayfun]); 

  const [postData, setPostData] = useState({
    order_unique_id: "",
    order_date: currentDate,
    product_details: {},
    pro_quantity: qun,
    user_details: {},
    user_id: "",
    customer_ip: "",
    phone: "",
    alt_phone: "",
    Address: "",
    location: "",
    pincode: "",
    payment: "",
    payment_id: "",
    track: "order process",
    binary_track: "0",
    amount: "",
    size: size,
    Track_product: "",
    expected_date: "",
  });

  const secret = process.env.REACT_APP_SECRET;
  const payload = {
    iss: process.env.REACT_APP_SECRET_ISS,
    aud: process.env.REACT_APP_SECRET_AUD,
  };
  const header = {
    alg: process.env.REACT_APP_SECRET_ALG,
  };
  const encodeHeader = btoa(JSON.stringify(header));
  const payloadString = btoa(JSON.stringify(payload));
  const signature = CryptoJS.HmacSHA256(
    encodeHeader + "." + payloadString,
    secret
  ).toString(CryptoJS.enc.Base64);
  const jwt = `${encodeHeader}.${payloadString}.${signature}`;
  const api = process.env.REACT_APP_API_CHECK;
  const dataenv = process.env.REACT_APP_DATA;
  const apia = process.env.REACT_APP_API_CHECK_A;

  const product_views = () => {
    if (size.charAt(size.length - 1) === "C") {
      axios
        .get(`https:${api}/${apicall}/?id=${productId}`, {
          headers: {
            "Content-Type": `${dataenv}`,
            Authorization: `Bearer ${jwt}`,
          },
        })
        .then((response) => {
          setstore(response.data);
          setPostData((prevData) => ({
            ...prevData,
            product_details: response.data,
            amount: response.data.discount,
          }));
        })
        .catch((error) => {
          console.error("Error posting data:", error);
        });
    } else {
      axios
        .get(`https:${apia}?id=${productId}`, {
          headers: {
            "Content-Type": "multipart/dataToPost",
            Authorization: `Bearer ${jwt}`,
          },
        })
        .then((response) => {
          setstore(response.data);
          setPostData((prevData) => ({
            ...prevData,
            product_details: response.data,
            amount: response.data.discount,
          }));
        })
        .catch((error) => {
          console.error("Error posting data:", error);
        });
    }
  };
  const handleIncrement = () => {
    if (store[size] > qun) {
      setQuan(qun + 1);
      setPostData((prevData) => ({
        ...prevData,
        pro_quantity: qun + 1,
        amount: store.discount * (qun + 1),
      }));
    }
  };

  const handleDecrement = () => {
    if (qun > 1) {
      setQuan(qun - 1);
      setPostData((prevData) => ({
        ...prevData,
        pro_quantity: qun - 1,
        amount: store.discount * (qun - 1),
      }));
    }
  };

  useEffect(() => {
    product_views();
    setPostData((prevData) => ({
      ...prevData,
      user_details: user,
      user_id: user.user_id,
    }));
    // eslint-disable-next-line
  }, []);

  if (!store || store.message === "No data found for the specified ID.") {
    return <div>loading</div>;
  }

  const acceptorder = process.env.REACT_APP_API_CHECK_B;

  const order_accept = () => {
    axios
      .post(`https:${acceptorder}`, postData, {
        headers: {
          "Content-Type": `${dataenv}`,
          Authorization: `Bearer ${jwt}`,
        },
      })
      .then((response) => {
        console.log(response.data)
        toast.success("successfully order");
        toast.success("order confirm");
        navigate("/");
      })
      .catch((error) => {
        console.error("Error posting data:", error);
      });
    setCheckpay(false);
    setPaycheck(1);
    setSuccessful(true);
  };

  const dcheck = process.env.REACT_APP_API_CHECK_C;
  async function showRazorpay() {
    const res = await loadScript(`https:${dcheck}`);

    if (!res) {
      alert("Razorpay SDK failed to load. Are you online?");
      return;
    }

    const echeck = process.env.REACT_APP_API_CHECK_D;

    const options = {
      key: `${echeck}`,
      currency: "INR", // Change this to your desired currency
      amount: (store.discount * qun - discountAmount)*100, // Change this to the desired amount in paise or cents
      name: "purchase",
      description: "your bill account",
      image: Img1,
      handler: function (response) {
        if (response.razorpay_payment_id) {
          setPostData((prevData) => ({
            ...prevData,
            payment_id: response.razorpay_payment_id,
          }));
          setPostData((prevData) => ({
            ...prevData,
            payment: "online mode",
          }));
          setCheckpay(true);
          setCheckpayfun(true);
        } else {
          alert("Transaction failed. Please try again later.");
          // Aap aur bhi error handling kar sakte hain, jaise ki error message set karke state mein store karke use display karna.
        }
      },
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  }
  const offline = () => {
    setGateway(false);
    setCheckpay(true);
    setCod(0)
    setPostData((prevData) => ({
      ...prevData,
      payment: "Cash on delivery",
      amount: (totalamount + 50)
    }));
  };

  const online = () => {
    setGateway(!gateway);
    setCheckpay(false);
    setCod(1);
    setPostData((prevData) => ({
      ...prevData,
      payment: "Cash on delivery",
      amount: (totalamount)
    }));
  };

  const adress_amount = () => {
    setAddress(!address);
    setTotalamount((store.discount * qun) - discountAmount);
  };

  const applyCoupon = () => {
    const couponCodeToApply = "NEWMEMBER10"; // Coupon code to apply discount
    if (couponCode === couponCodeToApply) {
      const couponDiscountPercentage = 0.1; // 10% discount
      const discountedAmount = Math.round(store.discount * qun * couponDiscountPercentage);
      console.log(discountAmount);
      setDiscountAmount(discountedAmount);
      setPostData((prevState) => ({
        ...prevState,
        amount: store.discount * qun - discountedAmount,
      }));
    } else {
      // Reset discount amount if the coupon code is invalid or not matching
      setDiscountAmount(0);
      // Reset the amount in postData to the original total price
      setPostData((prevState) => ({
        ...prevState,
        amount: store.discount * qun, // Reset amount to original total price
      }));
      // Optionally, you can display a message indicating that the coupon code is invalid
      alert("Invalid coupon code. Please enter a valid coupon code.");
    }
  };
  return (
    <>
      <div>
        {!address ? (
          <div className="product_bill_container">
            <div className="product_checkout">
              <div className="img_checkout">
                <img
                  src={
                    size.charAt(size.length - 1) === "C"
                      ? `${process.env.REACT_APP_API_IMAGE}${store.thumnail}`
                      : `${process.env.REACT_APP_API_IMAGE_SECOND}${store.thumnail}`
                  }
                  alt=""
                  width="100%"
                />
              </div>
              <div className="check_pro_details">
                <p className="p_part1_checkout">{store.name}</p>
                <p className="p_part2_checkout">
                  {store.description.split(" ").slice(0, 10).join(" ")}
                  {store.description.split(" ").length > 10 && "..."}
                </p>
                <p className="p_part3_checkout">RS: {store.discount}</p>
                <p className="p_part3_checkout">size: {size.replace(/noC|no/g, "").toUpperCase()}</p>
                <div className="quantity_checkout_container">
                  {store[size] > qun ? (
                    <div className="quantity_checkout">
                      <p className="p_checkout_quan">Qun</p>
                      <div>
                        <button onClick={handleDecrement} className="but_1">
                          <RiSubtractFill />
                        </button>
                      </div>
                      <div className="input_checkout">
                        <input type="number" value={qun} readOnly />
                      </div>
                      <div>
                        <button onClick={handleIncrement} className="but_2">
                          +
                        </button>
                      </div>
                    </div>
                  ) : (
                    <div className="quantity_checkout">
                      <p>Quantity</p>
                      <div>
                        <button onClick={handleDecrement} className="but_1">
                          <RiSubtractFill />
                        </button>
                      </div>
                      <div className="input_checkout">
                        <input type="number" value={qun} readOnly />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="product_summary">
              <div className="head_price_sum">
                <p>PRICE SUMMARY</p>
              </div>
              <div className="summary_bill">
                <div className="con_bill">
                  <p>Total (Incl. of taxes)</p>
                </div>
                <div className="con_bill">
                  <p>RS: {store.discount * qun}</p>
                </div>
              </div>
              {/* <div className="summary_bill">
                <div className="con_bill">
                  <p>Shipping Charges</p>
                </div>
                <div className="con_bill">
                  <p>FREE</p>
                </div>
              </div> */}
              <div className="summary_bill">
                <div className="con_bill">
                  <p>Subtotal</p>
                </div>
                <div className="con_bill">
                  <p>RS: {store.discount * qun}</p>
                </div>
              </div>
              <div className="summary_bill">
                <div className="con_bill">
                  <p>Coupon Apply</p>
                </div>
                <div className="con_bill">
                  <input
                    type="text"
                    value={couponCode}
                    onChange={(e) => setCouponCode(e.target.value)}
                  />
                  <button onClick={applyCoupon}>Apply</button>
                </div>
              </div>
              {discountAmount > 0 && (
                <div className="summary_bill">
                  <div className="con_bill">
                    <p>Coupon Discount</p>
                  </div>
                  <div className="con_bill">
                    <p>RS: {discountAmount}</p>
                  </div>
                </div>
              )}
              <hr className="hr_space" />
              <div className="con_bill_add">
                <div className="con_bill_part2">
                  <p>Sub Total</p>
                  <p>{store.discount * qun - discountAmount}</p>
                </div>
                <div
                  className="button_add_address"
                  onClick={adress_amount}
                >
                  <p>ADD ADDRESS</p>
                </div>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        {address && !binpay && totalamount > 0 ? (
          <div className="shipping_checkout">
            <div className="ship_head">
              <p>Shipping details:</p>
            </div>
            <div className="ship_input-container">
              <div className="wrap_ship">
                <div className="ship_input">
                  <input
                    type="text"
                    placeholder="pincode"
                    value={postData.pincode}
                    id="pin"
                    pattern="[0-9]{6}"
                    onChange={(e) =>
                      setPostData({ ...postData, pincode: e.target.value })
                    }
                  />
                </div>
                <div className="ship_input">
                  <input
                    type="text"
                    placeholder="Address"
                    value={postData.Address}
                    onChange={(e) =>
                      setPostData({ ...postData, Address: e.target.value })
                    }
                  />
                </div>
                <div className="ship_input">
                  <input
                    type="text"
                    placeholder="City/town"
                    value={postData.location}
                    onChange={(e) =>
                      setPostData({ ...postData, location: e.target.value })
                    }
                  />
                </div>
                <div className="ship_input">
                  <input
                    type="text"
                    placeholder="Mobile number"
                    value={postData.phone}
                    onChange={(e) =>
                      setPostData({ ...postData, phone: e.target.value })
                    }
                  />
                </div>
                <div className="ship_input">
                  <input
                    type="text"
                    placeholder="alternate number"
                    value={postData.alt_phone}
                    onChange={(e) =>
                      setPostData({ ...postData, alt_phone: e.target.value })
                    }
                  />
                </div>
              </div>
            </div>
            <div onClick={() => { setBinpay(!binpay); setLastdetail(1) }} className="ship_next">
              <div className="but_ship_next">Next step</div>
            </div>
          </div>
        ) : (
          ""
        )}
        {binpay && !successful ? (
          <div className="shipping_checkout">
            <div className="ship_head">
              <p>Payment details:</p>
            </div>
            <div className="payment_content">
              <p>Please select the payment mode, then confirm the order.</p>
            </div>
            <div className="ship_input-container">
              <div className="wrap_ship">
                <div>
                  <div onClick={offline} className="con_pay_check">
                    <p>Cash On delivery</p>
                  </div>
                  <div
                    onClick={online}
                    className="con_pay_check"
                  >
                    <p>Online payment</p>
                  </div>
                  {gateway ? (
                    <div>
                      <header>
                        <button
                          onClick={showRazorpay}
                          className="but_ship_next"
                        >
                          Pay
                        </button>
                      </header>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        {
          lastdetail > 0 && !successful && (
            <div className="last_summary">
              <div className="product_summary">
                <div className="head_price_sum">
                  <p>PRICE SUMMARY</p>
                </div>
                <div className="summary_bill">
                  <div className="con_bill">
                    <p>Total (Incl. of taxes)</p>
                  </div>
                  <div className="con_bill">
                    <p>RS: {store.discount * qun}</p>
                  </div>
                </div>
                <div className="summary_bill">
                  <div className="con_bill">
                    <p>Shipping Charges</p>
                  </div>
                  <div className="con_bill">
                    <p>{cod > 0 ? "FREE" : "RS: 50"}</p>
                  </div>
                </div>
                <div className="summary_bill">
                  <div className="con_bill">
                    <p>Subtotal</p>
                  </div>
                  <div className="con_bill">
                    <p>RS: {store.discount * qun}</p>
                  </div>
                </div>
                {discountAmount > 0 && (
                  <div className="summary_bill">
                    <div className="con_bill">
                      <p>Coupon Discount</p>
                    </div>
                    <div className="con_bill">
                      <p>RS: {discountAmount}</p>
                    </div>
                  </div>
                )}
                <hr className="hr_space" />
                <div className="con_bill_add">
                  <div className="con_bill_part2">
                    <p>Total</p>
                  </div>
                  <div>
                    <p>RS: {postData.amount}</p>
                  </div>
                </div>
              </div>
            </div>
          )
        }
        {
          paycheck > 0 && !successful &&
          <><div className="payment_success"><p>Your payment has been successfully completed. Now, please click the confirm button to confirm your order.</p></div></> 
        }
        {checkpay && !successful ? (
          <div className="butt_accept">

            <button onClick={order_accept} className="but_ship_next">
              Order Confirm
            </button>{" "}
          </div>
        ) : (
          ""
        )}
        {successful ? <div> you order has been accept . </div> : ""}
      </div>
    </>
  );
};

export default Checkout;
